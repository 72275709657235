import { ref, useContext, useFetch } from '@nuxtjs/composition-api';
import orderBy from 'lodash.orderby';
import { Course } from '@/shared/types';
import getAllCourses from '@/shared/graphql/courses/AllCourses.gql';
import {
  GetAllCoursesQuery,
  GetAllCoursesQueryVariables,
} from '@/shared/graphql/schema.types';

export default function useGetAllCourses(isInPreviewMode: boolean = false) {
  const allCourses = ref<Partial<Course>[]>([]);
  const allCoursesFetchingErrorOccured = ref<Boolean>(false);
  const isFetchingAllCourses = ref<Boolean>(true);
  const ctx = useContext();

  const variables = {
    usePreview: isInPreviewMode,
    ...(!isInPreviewMode ? { organisationCode: 'ADV' } : {}),
  };

  const fetchAllCourses = async () => {
    try {
      const { data } = await ctx.$gqlFetch<
        GetAllCoursesQuery,
        GetAllCoursesQueryVariables
      >(getAllCourses, variables);

      isFetchingAllCourses.value = false;

      allCourses.value = orderBy(
        data.courses as Partial<Course>[],
        (course) => course.title
      );
    } catch (e) {
      allCoursesFetchingErrorOccured.value = true;
      isFetchingAllCourses.value = false;
    }
  };

  useFetch(fetchAllCourses);

  return {
    allCourses,
    allCoursesFetchingErrorOccured,
    isFetchingAllCourses,
    fetchAllCourses,
  };
}
