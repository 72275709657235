import { gql } from '@/shared/utils';

export default gql`
  query GetAllCourses($organisationCode: String, $usePreview: Boolean) {
    courses(organisationCode: $organisationCode, usePreview: $usePreview) {
      _id
      category {
        name
        backgroundImage
        themeColorHex
      }
      slug
      duration
      title
      shortDescription
      nextAvailableDate
    }
  }
`;
